@import "styles/variables.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.content {
  min-height: 100vh;
  flex: 1 0 auto;
}
