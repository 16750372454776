@import "styles/variables.scss";
.logo {
  height: 50px;
  cursor: pointer;
  position: relative;
}
.navBlock {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  .textColor {
    color: $very-dark-gray !important;
  }
  .userImage {
    width: 20px;
    height: 24px;
  }
  .btn:focus {
    outline: none !important;
  }
  .btn {
    border: none;
    transition: none !important;
  }
}
.blockMenu {
  display: block !important;
  padding-top: 0 !important;
}
.show {
  display: block;
}
.toggleBtn {
  padding-right: 1rem !important;
  z-index: 6;
  display: block;
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  background-color: inherit;
  border: none;
  outline: none !important;
  span,
  span::before,
  span::after {
    position: absolute;
    width: 37px;
    height: 1px;
    background: $very-dark-gray;
  }
  span::before,
  span::after {
    content: '';
    display: block;
    transition: 0.22s;
  }
  span::before {
    transform: translateY(-7px);
  }
  span::after {
    transform: translateY(7px);
  }
}
.toggleBtnActive {
  span::before {
    transform: rotate(45deg) !important;
  }
  span::after {
    transform: rotate(-45deg);
  }
  span {
    height: 0;
  }
}

@media screen and (max-width: 1560px) {
  .logo {
    left: 30px;
  }
}
@media screen and (max-width: 1550px) {
  .logo {
    left: 25px;
  }
}
@media screen and (max-width: 1545px) {
  .logo {
    left: 20px;
  }
}
@media screen and (max-width: 1540px) {
  .logo {
    left: 15px;
  }
}
@media screen and (max-width: 1535px) {
  .logo {
    left: 10px;
  }
}
@media screen and (max-width: 1530px) {
  .logo {
    left: 5px;
  }
}
@media screen and (max-width: 1525px) {
  .logo {
    left: 0;
  }
}
@media screen and (max-width: 992px) {
  .navBlock {
    display: block;
  }
}
@media screen and (max-width: 992px) {
  .userImage {
    display: none;
  }
}

@media screen and (min-width: 991px) {
  .show {
    display: block !important;
  }
}
